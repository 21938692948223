<template>
  <div class="product-details">
    <b-container class="py-3">
      <section class="pb-3">
        <b-row>
          <b-col cols="12" md="6" order="1" order-md="0">
            <div
              class="porduct-caption py-5 px-2"
              data-aos="fade-right"
              data-aos-duration="200"
            >
              <div class="caption-header d-flex flex-column">
                <h1 class="text-custom-blue-sky">
                  {{ productDto.name }}
                </h1>
                <h3 class="text-light">
                  {{ productDto.mainCategory }}
                </h3>
              </div>
              <div class="desc my-2">
                <p class="lead text-light">
                  {{ productDto.description }}
                </p>
              </div>
              <div class="d-flex flex-column">
                <h5 class="text-light mb-1">
                  -
                  {{ productDto.foodSuitability ? "" : t("product").details.no }}
                  {{ t("product").details.foodSuitable }}
                </h5>
                <h5 class="text-light">
                  -
                  {{
                    t("product").details.limit +
                    " " +
                    (currType ? productDto.limitOutside : productDto.limit)
                  }}
                </h5>
                <h6 class="text-custom-blue-sky mt-3">
                  {{ t("product").details.productPrice }}
                </h6>
                <h3 class="text-light" v-if="cartDto.measurement && !currType">
                  {{
                    productDto.measurements.find((pr) => pr.id == cartDto.measurement)
                      .syrianValue | numFormat("0,0.000")
                  }}
                  {{ t("sp") }}
                </h3>
                <h3 class="text-light" v-if="cartDto.measurement && currType">
                  {{
                    productDto.measurements.find((pr) => pr.id == cartDto.measurement)
                      .dollarValue | numFormat("0,0.000")
                  }}
                  {{ t("usd") }}
                </h3>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-4 mt-md-0">
            <div class="product-carousel">
              <b-row>
                <b-col cols="12" md="9">
                  <div
                    class="w-100 h-100 d-flex align-items-center justify-content-center"
                  >
                    <div
                      class="d-flex flex-column align-items-center"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <div class="product-img">
                        <img
                          v-if="productDto.measurements.length"
                          :src="
                            $store.getters['app/domainHost'] +
                            '/' +
                            productDto.measurements[currentImgIndex].path
                          "
                        />
                        <b-button
                          v-if="productDto.path3D"
                          variant="custom-blue"
                          block
                          class="my-2 p-50"
                          style="font-size: 12px"
                          @click="preview3d"
                        >
                          {{ $t("website.threedPreview") }}
                          <unicon
                            height="15"
                            fill="#fff"
                            name="cube"
                            class="mx-25"
                          ></unicon>
                        </b-button>
                      </div>
                      <div
                        class="mt-5 counter d-flex w-100 justify-content-center align-items-center"
                      >
                        <b-button
                          @click="cartDto.quantity++"
                          class="rounded-circle quantity"
                          variant="custom-blue-sky"
                        >
                          <unicon name="plus" width="18" height="18" fill="#fff"></unicon>
                        </b-button>
                        <EKInputText
                          class="mx-2"
                          fieldClass="input-field borderd"
                          v-model="cartDto.quantity"
                          name="quantity"
                          type="number"
                          :min="0"
                        />
                        <b-button
                          @click="
                            cartDto.quantity > 1
                              ? cartDto.quantity--
                              : (cartDto.quantity = 1)
                          "
                          class="rounded-circle quantity"
                          variant="custom-blue-sky"
                        >
                          <unicon
                            name="minus"
                            width="18"
                            height="18"
                            fill="#fff"
                          ></unicon>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div
                    class="d-none d-md-block"
                    data-aos="fade-up"
                    data-aos-duration="2500"
                  >
                    <swiper
                      :options="{
                        direction: 'vertical',
                        slidesPerView: 4,
                      }"
                      class="products-carousel mt-4 mt-md-0"
                      v-if="productDto.images.length"
                      @click-slide="toNextSlide"
                    >
                      <swiper-slide
                        v-for="(img, i) in [...productDto.measurements]"
                        :key="i"
                        :class="{
                          activeImage: i == currentImgIndex,
                        }"
                        @click="
                          typeof img == 'string'
                            ? () => false
                            : (cartDto.measurement = img.id)
                        "
                      >
                        <img
                          :src="
                            typeof img == 'string'
                              ? $store.getters['app/domainHost'] + '//' + img
                              : $store.getters['app/domainHost'] + '//' + img.path
                          "
                          class="rounded"
                          width="80"
                        />
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="d-md-none" data-aos="fade-up" data-aos-duration="2500">
                    <swiper
                      :options="{ slidesPerView: 3 }"
                      class="mt-4 mt-md-0"
                      v-if="productDto.images.length"
                      @click-slide="toNextSlide"
                    >
                      <swiper-slide
                        v-for="(img, i) in [
                          ...productDto.images,
                          ...productDto.measurements,
                        ]"
                        :key="i"
                        :class="{
                          activeImage: i == currentImgIndex,
                        }"
                        class="text-center"
                        @click="
                          typeof img == 'string'
                            ? () => false
                            : (cartDto.measurement = img.id)
                        "
                      >
                        <img
                          :src="
                            typeof img == 'string'
                              ? $store.getters['app/domainHost'] + '//' + img
                              : $store.getters['app/domainHost'] + '//' + img.path
                          "
                          class="rounded"
                          width="80"
                          height="80"
                        />
                      </swiper-slide>
                    </swiper>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </section>
      <section class="uses">
        <b-row>
          <b-col>
            <div class="custom-shadow px-5 py-1 rounded">
              <h4 class="text-custom-blue-sky m-0">
                {{ t("product").details.measurements }}
              </h4>
            </div>
            <b-container class="pt-3">
              <b-form-radio-group
                v-model="cartDto.measurement"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <EkTable
                  no_select
                  no_delete
                  :items="productDto.measurements"
                  class="cart-table rounded mt-2"
                  :columns="measurCols"
                >
                  <template slot="items.radio" scope="{props}">
                    <b-radio :value="props.row.id" class="select-radio"> </b-radio>
                  </template>
                  <template slot="headers" slot-scope="{ props }">
                    <span>
                      {{ t("product.details")[props.column.field] }}
                    </span>
                  </template>

                  <template slot="items.length" scope="{value}">
                    {{ value + " " + t("product").details.cm }}
                  </template>
                  <template slot="items.width" scope="{value}">
                    {{ value + " " + t("product").details.cm }}
                  </template>
                  <template slot="items.height" scope="{value}">
                    {{ value + " " + t("product").details.cm }}
                  </template>
                  <template slot="items.capacityLiter" scope="{value}">
                    {{ value ? value + " " + t("product").details.liter : "-" }}
                  </template>
                  <template slot="items.capacityKilo" scope="{value}">
                    {{ value ? value + " " + t("product").details.kg : "-" }}
                  </template>
                  <template slot="items.syrianValue" scope="{props}">
                    <template v-if="!currType">
                      {{ props.row.syrianValue | numFormat("0,0.000") }}
                      {{ t("sp") }}
                    </template>
                    <template v-else>
                      {{ props.row.dollarValue | numFormat("0,0.000") }}
                      {{ t("usd") }}
                    </template>
                  </template>
                </EkTable>
              </b-form-radio-group>
            </b-container>
          </b-col>
          <b-col cols="12" lg="6" v-if="productDto.uses.length">
            <div class="custom-shadow px-5 py-1 rounded">
              <h4 class="text-custom-blue-sky m-0">
                {{ t("product").details.uses }}
              </h4>
            </div>
            <div class="uses-content pt-3" data-aos="fade-up" data-aos-duration="2500">
              <b-container>
                <h5 v-for="use in productDto.uses" class="my-1 mb-2" :key="use">
                  - {{ use }}
                </h5>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </section>
      <section class="additions" v-if="productDto.addons.length">
        <b-form-checkbox-group v-model="cartDto.addons">
          <div class="custom-shadow px-5 py-1 rounded">
            <h4 class="text-custom-blue-sky m-0">
              {{ $t("website.other-addons") }}
            </h4>
          </div>
          <div class="props my-5">
            <b-container>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-for="(addon, i) in productDto.addons"
                  :key="addon.id"
                >
                  <label
                    :for="'cb' + i"
                    data-aos="fade-up"
                    :data-aos-duration="1000 * ((i % 3) + 1)"
                  >
                    <div class="add-card">
                      <div class="image-2">
                        <div class="category-image-card-border rounded">
                          <div class="category-image-card rounded">
                            <div class="sub-category rounded"></div>
                          </div>
                        </div>
                        <div class="category-content d-flex align-items-center">
                          <div
                            class="d-flex justify-content-between w-100 alig-items-center"
                          >
                            <h5 class="text-light m-0 d-flex alig-items-center">
                              <b-checkbox :id="'cb' + i" :value="addon"></b-checkbox>
                              {{ addon.attributeName }}
                            </h5>
                            <h5 v-if="!currType" class="text-light m-0">
                              (
                              {{ addon.syrianValue }}
                              {{ t("sp") }})
                            </h5>
                            <h5 v-else class="text-light m-0">
                              (
                              {{ addon.dollarValue }}
                              {{ t("usd") }})
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-form-checkbox-group>
      </section>
      <section class="total-result my-5">
        <div
          class="wrapper-container d-md-flex custom-shadow rounded"
          style="padding: 0 !important"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div class="wrapper pr-1 p-2">
            <div
              class="skew-content w-100 d-flex align-items-center justify-content-between"
            >
              <h6 class="text-custom-blue-sky mb-0 mx-md-1">
                {{ t("product").details.productPrice }}
              </h6>
              <h6 class="text-light m-0 mx-1" v-if="cartDto.measurement && !currType">
                ({{
                  productDto.measurements.find((pm) => pm.id == cartDto.measurement)
                    .syrianValue | numFormat("0,0.000")
                }}
                {{ t("sp") }})
              </h6>
              <h6 class="text-light m-0 mx-1" v-if="cartDto.measurement && currType">
                ({{
                  productDto.measurements.find((pm) => pm.id == cartDto.measurement)
                    .dollarValue | numFormat("0,0.000")
                }}
                {{ t("usd") }})
              </h6>
            </div>
          </div>
          <div class="wrapper pr-1 p-2">
            <div
              class="skew-content w-100 d-flex align-items-center justify-content-between"
            >
              <h6 class="text-custom-blue-sky m-0">
                {{ t("product").details.quantity }}
              </h6>
              <h6 class="text-light m-0 mx-2">({{ cartDto.quantity }})</h6>
            </div>
          </div>
          <div class="wrapper pr-1 p-2" v-for="addon in cartDto.addons" :key="addon.id">
            <div
              class="skew-content w-100 d-flex align-items-center justify-content-between"
            >
              <h6 class="text-custom-blue-sky m-0">
                {{ addon.attributeName }}
              </h6>
              <h6 class="text-light m-0 mx-2" v-if="!currType">
                ({{ addon.syrianValue | numFormat("0,0.000") }} {{ t("sp") }})
              </h6>
              <h6 class="text-light m-0 mx-2" v-else>
                ({{ addon.dollarValue | numFormat("0,0.000") }})
              </h6>
            </div>
          </div>
          <div class="wrapper pr-1 p-2 mr-auto">
            <div
              class="skew-content w-100 d-flex align-items-center justify-content-between"
            >
              <h6 class="text-custom-blue-sky m-0">
                {{ t("product").details.totalPrice }}
              </h6>
              <h6 class="text-light m-0 mx-2" v-if="!currType">
                ({{ productDetailsTotalPrice | numFormat("0,0.000") }} {{ t("sp") }})
              </h6>
              <h6 class="text-light m-0 mx-2" v-else>
                ({{ productDetailsTotalPrice | numFormat("0,0.000") }} {{ t("usd") }})
              </h6>
            </div>
          </div>
          <div class="rounded border-0">
            <b-button
              @click="submitSetToCart()"
              class="w-100 h-100 px-3"
              variant="custom-blue"
            >
              <div class="skew-content">
                {{ t("product").details.addToCart }}
                <unicon name="shopping-cart" fill="#fff"></unicon>
              </div>
            </b-button>
          </div>
        </div>
      </section>
      <div class="threeD-modal" v-if="preview3dModel">
        <model-viewer
          class="w-100"
          ref="modelviewer"
          interaction-prompt="auto"
          style="height: 100%"
          :src="`${$store.getters['app/domainHost']}/${productDto.path3D}`"
          ar
          ar-modes="webxr scene-viewer quick-look"
          camera-controls
          environment-image="neutral"
          shadow-intensity="1.8"
          exposure="1.08"
          auto-rotate
        >
          <button slot="ar-button" id="ar-button">View in AR</button>
        </model-viewer>
        <b-button
          class="mt-3 p-0 rounded-circle close-modal-btn"
          variant="danger"
          @click="close3D"
        >
          <unicon name="times" width="20" fill="#fff"></unicon>
        </b-button>
      </div>
    </b-container>
  </div>
</template>
<style>
#progress {
  position: absolute;
  width: calc(100% / 3);
  height: 10px;
  color: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.25);
  border: 2px solid currentColor;
  border-radius: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 100% / 6);
  opacity: 0;
  transition: opacity 0.3s 0.3s;
}

#progress.show {
  transition-delay: 0s;
  opacity: 1;
}

#progress > .bar {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  transform-origin: top left;
  transform: scaleX(0);
}
</style>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import EkTable from "@/EK-core/components/EK-table";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { getCookie } from "@Ekcore/util/global";
import { handleLangChange } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { nullGuid } from "@/EK-core/util/global";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
export default {
  components: {
    EkTable,
    Swiper,
    SwiperSlide,
    EKInputText,
  },
  data() {
    return {
      measurCols: [
        {
          label: "المقاسات",
          field: "radio",
          sortable: false,
        },
        {
          label: "القطر السفلي",
          field: "lowerDiameter",
          sortable: false,
        },
        {
          label: "القطر العلوي",
          field: "upperDiameter",
          sortable: false,
        },
        {
          label: "الطول",
          field: "length",
          sortable: false,
        },
        {
          label: "العرض",
          field: "width",
          sortable: false,
        },
        {
          label: "الارتفاع",
          field: "height",
          sortable: false,
        },
        {
          label: "السعة ب ليتر",
          field: "capacityLiter",
          sortable: false,
        },
        {
          label: "السعة ب كغ",
          field: "capacityKilo",
          sortable: false,
        },
        {
          label: "السعر",
          field: "syrianValue",
          sortable: false,
        },
      ],
      currentImgIndex: 0,
      screenSize: null,
      preview3dModel: false,
    };
  },
  metaInfo() {
    return {
      titleTemplate:
        "Awija - " + this.$t("website.allproducts") + " " + this.productDto.name,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: "description",
          content: this.productDto.description,
        },
        {
          name: "keywords",
          content: "keywords, keywords, keywords, ...",
        },
      ],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      productDto: (state) => state.webProducts.productDto,
      cartDto: (state) => state.webProducts.cartDto,
      currType: (state) => state.global.currType,
    }),
    ...mapGetters(["productDetailsTotalPrice", "productActiveLimit"]),
    screenStatus() {
      return this.screenSize > 767;
    },
    productActiveLimit() {
      return this.currType == 1 ? this.productDto.limitOutside : this.productDto.limit;
    },
  },
  created() {
    this.getWebsiteProductDetails(this.id).then(({ data }) => {
      this.init(data);
    });
    handleLangChange(() => {
      this.getWebsiteProductDetails(this.id).then(({ data }) => {
        this.init(data);
      });
    });
    this.screenSize = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenSize = window.innerWidth;
    });
  },
  watch: {
    productDto: {
      handler(val) {
        if (!val.isCircleShape)
          this.measurCols = [
            {
              label: "المقاسات",
              field: "radio",
              sortable: false,
            },
            {
              label: "الطول",
              field: "length",
              sortable: false,
            },
            {
              label: "العرض",
              field: "width",
              sortable: false,
            },
            {
              label: "الارتفاع",
              field: "height",
              sortable: false,
            },
            {
              label: "السعة ب ليتر",
              field: "capacityLiter",
              sortable: false,
            },
            {
              label: "السعة ب كغ",
              field: "capacityKilo",
              sortable: false,
            },
            {
              label: "السعر",
              field: "syrianValue",
              sortable: false,
            },
          ];
        else
          this.measurCols = [
            {
              label: "المقاسات",
              field: "radio",
              sortable: false,
            },
            {
              label: "القطر السفلي",
              field: "lowerDiameter",
              sortable: false,
            },
            {
              label: "القطر العلوي",
              field: "upperDiameter",
              sortable: false,
            },
            {
              label: "الارتفاع",
              field: "height",
              sortable: false,
            },
            {
              label: "السعة ب ليتر",
              field: "capacityLiter",
              sortable: false,
            },
            {
              label: "السعة ب كغ",
              field: "capacityKilo",
              sortable: false,
            },
            {
              label: "السعر",
              field: "syrianValue",
              sortable: false,
            },
          ];
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["getWebsiteProductDetails", "addToCart", "getCartItems"]),
    ...mapMutations(["Update_Cart_Quantity_Limit"]),
    init(data) {
      this.$store.commit("GET_WEBSITE_PRODUCT_DETAILS", data);
      this.cartDto.measurement = data.measurements[0].id;
      this.cartDto.name = data.name;
      this.cartDto.id = data.id;
      this.Update_Cart_Quantity_Limit(this.productActiveLimit);
      this.cartDto.mainCategory = data.mainCategory;
    },

    preview3d() {
      this.preview3dModel = true;
      document.querySelector("body").classList.add("overflow-hidden");

      //console.log(document.querySelector('sell'));
      //const modelViewer = document.querySelector("model-viewer");

      ////const modelViewer = this.$el.querySelector('#model-viewer');

      //const progress = this.$el.querySelector('#progress');
      //const bar = this.$el.querySelector('.bar');
      //modelViewer.addEventListener('progress', (event) => {
      //    const { totalProgress } = event.detail;
      //    progress.classList.toggle('show', totalProgress < 1);
      //    bar.style.transform = `scaleX(${totalProgress})`;
      //});
    },
    close3D() {
      this.preview3dModel = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    toNextSlide(e) {
      this.currentImgIndex = e;
    },
    quantityEqualOrAboveLimit() {
      return this.cartDto.quantity >= this.productActiveLimit;
    },
    submitSetToCart() {
      if (this.quantityEqualOrAboveLimit()) {
        this.addToCart({
          hash: getCookie("awijaCart"),
          cartDto: {
            id: nullGuid,
            name: this.cartDto.name,
            image: this.productDto.measurements.find(
              (pm) => pm.id == this.cartDto.measurement
            ).path,
            quantity: this.cartDto.quantity,

            price: this.productDto.measurements.find(
              (pm) => pm.id == this.cartDto.measurement
            ).syrianValue,

            dollarPrice: this.productDto.measurements.find(
              (pm) => pm.id == this.cartDto.measurement
            ).dollarValue,

            productId: this.productDto.id,
            productMeasurementId: this.cartDto.measurement,
            limit: this.productDto.limit,
            attribute: this.cartDto.addons.map((add) => ({
              id: add.id,
              attributeId: add.attributeId,
              name: add.attributeName,
              price: add.syrianValue,
              dollarPrice: add.dollarValue,
            })),
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `اقل كمية ممكنة هي ` + this.productActiveLimit + " قطعة",
            icon: "CoffeeIcon",
            variant: "info",
          },
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("Reset_Cart_Dto");
    this.$store.commit("Reset_Product_Dto");
    window.removeEventListener("keypress", this.enterSubmit);
  },
};
</script>

<style scoped lang="scss">
.products-carousel {
  height: 360px;
  width: 80px;
}
.product-details {
  .desc {
    p {
      line-height: 1.8;
      color: #ccc !important;
    }
  }
  h5 {
    color: #ccc !important;
  }
}
.cart-table::v-deep {
  .page-item {
    border-radius: 50% !important;
    margin: 0 5px;
    padding: 3px;
    height: 35px !important;
    width: 35px !important;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(165, 145, 145);
    background: linear-gradient(
      -180deg,
      rgba(238, 238, 238, 1) 0%,
      rgba(71, 73, 74, 1) 100%
    );
    .page-link {
      background-color: #47494a !important;
      color: #fff;
      border-radius: 5rem;
      border: 0;
      max-height: 35px !important;
      max-width: 35px !important;
    }
  }
  .page-item.active {
    height: 42px !important;
    width: 42px !important;
    padding: 2px;
    background: rgb(26, 201, 251);
    background: linear-gradient(
      180deg,
      rgba(26, 201, 251, 1) 0%,
      rgb(106, 109, 110) 100%
    );
    position: relative;
    top: -5px;

    .page-link {
      top: 0 !important;
      color: #1ac9fb !important;
      max-height: 39px !important;
      max-width: 39px !important;
    }
  }

  .prev-item,
  .next-item {
    display: none !important;
  }
  * {
    border: #ccc !important;
  }
  tr {
    border: #777 1px solid !important;
  }
  td,
  h4 {
    color: rgb(179, 175, 175) !important;
  }
  .vgt-table,
  .card {
    border: #ccc 1px solid !important;
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .vgt-table {
    border: 0 !important;
  }
  .card-header {
    padding: 0;
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .card-footer {
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 6px !important;
  }

  .table-header {
    width: 100%;
    border-bottom: 1px #eee solid !important ;
    border-radius: 10px 10px 0px 0px;
  }
  thead {
    th {
      font-weight: normal;
      color: #fff;
      border-bottom: #eee solid 1px !important;
      border: 0;
    }
  }
}
.metal-border {
  background: rgb(204, 204, 204);
  background: linear-gradient(
    342deg,
    rgba(204, 204, 204, 1) 0%,
    rgba(238, 238, 238, 1) 25%,
    rgba(119, 119, 119, 1) 50%,
    rgba(238, 238, 238, 1) 75%,
    rgba(119, 119, 119, 1) 100%
  );
  padding: 1px !important;
}

.custom-bg {
  background-color: var(--custom-dark) !important;
  background: radial-gradient(
    104.33% 375.58% at 97.5% 94.44%,
    rgba(196, 196, 196, 0.07) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}
.custom-shadow {
  padding: 1.2rem 1rem !important;
  background: #47494a;
  box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.25),
    inset 2px -2px 4px rgba(0, 0, 0, 0.25);
}
.additions {
  .add-card {
    max-width: 400px;
    width: 100%;
    height: 120px;
    position: relative;
    transform: scale(0.6);
    transition: transform 0.3s, height 1s;
    margin: auto;
    .image-2 {
      perspective: 500px;
      transform-style: preserve-3d;
      position: relative;
      height: 100%;
      width: 400px;
      .category-image-card-border {
        transform-origin: right center;
        transform: scale(1.1) translate3d(-20px, -12px, -60px) rotate3d(24, 37, 0, -28deg)
          skew(3deg, -1deg);
        padding: 3px;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, #878787, #ffffff, #6d6d6d, #ffffff, #949494);
        .category-image-card {
          background: var(--custom-dark);
          height: 100%;
          width: 100%;
        }
      }
      .category-content {
        max-width: 300px;
        padding-top: 24px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        transform: translate3d(6px, 0, 140px);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 30px);
        text-align: center;
        img {
          margin-top: 18px;
          margin-right: -40px;
          width: 100%;
          max-width: 160px;
          max-height: 160px;
          object-fit: contain;
        }
      }
    }
  }
}
.total-result {
  .wrapper-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    .wrapper {
      display: flex;
      align-items: center;
      transform: skewX(-25deg);
      min-width: 20%;
      border-right: 1px solid #777;
      border-bottom: 1px solid #777;
      .skew-content {
        transform: skewX(25deg);
      }
    }
  }
}

.products-carousel::v-deep {
  .slick-track {
    max-height: 500px;
  }
}
.product-img {
  width: 250px !important;
  height: 260px !important;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .show-model {
    transition: 0.4s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .show-model {
      opacity: 1;
    }
  }
}
.counter {
  ::v-deep .input-field {
    background: transparent;
    border: solid 1px #fff;
    color: #fff;
    width: 100px;
    text-align: center;
  }
  .quantity {
    height: 30px;
    width: 30px;
    padding: 0;
    background: #1ac9fb;
    box-shadow: inset -2px 2px 5px rgba(255, 255, 255, 0.4),
      inset 2px -2px 6px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
}
.select-radio::v-deep {
  [dir] .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #196da7;
    background-color: #196da7;
  }
}

[dir="ltr"] {
  .image-2 {
    .category-image-card-border {
      transform: scale(1.1) translate3d(-20px, -12px, -60px) rotate3d(24, 37, 0, 28deg)
        skew(-3deg, -1deg) !important;
    }
  }
}
</style>

<style lang="scss">
.slider-img {
  width: 80px !important;
  margin: 12px 20px;
  img {
    object-fit: contain;
  }
  &.activeImage {
    transform: scale(1.1);
    img {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    }
  }
}

.threeD-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(219, 219, 219);
  background: radial-gradient(
    circle,
    rgba(219, 219, 219, 1) 0%,
    rgba(47, 47, 47, 1) 100%
  );

  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  .close-modal-btn {
    position: absolute;
    top: 10%;
    right: 5%;
    width: 30px;
    height: 30px;
    color: #fff;
    z-index: 20;
  }
}
model-viewer {
  #default-poster {
    background-color: none;
    background: rgb(219, 219, 219) !important;
    background: radial-gradient(
      circle,
      rgba(219, 219, 219, 1) 0%,
      rgba(47, 47, 47, 1) 100%
    ) !important;
  }
}

model-viewer#reveal {
  --poster-color: transparent;
  background: rgb(219, 219, 219) !important;
  background: radial-gradient(
    circle,
    rgba(219, 219, 219, 1) 0%,
    rgba(47, 47, 47, 1) 100%
  ) !important;
}
</style>
