var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.faildRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',_vm._b({staticClass:"ek-form-group",attrs:{"label-cols":_vm.labelCols,"label-for":'label-for-text-' + _vm.id}},'b-form-group',_vm.$attrs,false),[_c('b-form-input',_vm._b({class:_vm.fieldClass,attrs:{"id":'label-for-text-' + _vm.id,"trim":"","min":_vm.min,"max":_vm.max,"type":_vm.type,"state":_vm.faildRules
                    ? errors[0]
                        ? false
                        : valid
                        ? true
                        : null
                    : null},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}},'b-form-input',_vm.$attrs,false)),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }